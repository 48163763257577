const GROUPS_IN_PRIORITY_ORDER = [
  'CAR_001_PROTOTYPE',
  'CAR_002_PROTOTYPE',
  'CAR_003_PROTOTYPE',
  'CAR_004_PROTOTYPE',
  'CAR_005_PROTOTYPE',
  'CAR_006_PROTOTYPE',
  'CAR_007_PROTOTYPE',
  'CAR_008_PROTOTYPE',
  'CAR_009_PROTOTYPE',
  'CAR_010_PROTOTYPE',
  'Developers'
] // If actual group not present, group will be set to 'Default'

const ORG_NAME = "Cartisan Prototype"
const APP_NAME = "Cartisan Prototype"
const LOGO = "/images/Cartisan_Logo_White_Lockup1.png"
const LOGO_ALT = "CartisanPrototype"
const HELP_ICON = "/images/icons/question-mark.svg"
const CLOSE_ICON = "/images/icons/cancel.svg"
const BACK_ICON = "/images/icons/back.svg"
const FORWARD_ICON = "/images/icons/forward.svg"
const BURGER_ICON = "/images/icons/burger.svg"
const EYE_OPEN_ICON = "/images/icons/eye-open.svg"
const EYE_CLOSE_ICON = "/images/icons/eye-close.svg"

const DISCLAIMER = null

export {
  GROUPS_IN_PRIORITY_ORDER,
  ORG_NAME,
  APP_NAME,
  LOGO,
  LOGO_ALT,
  HELP_ICON,
  CLOSE_ICON,
  BURGER_ICON,
  BACK_ICON,
  FORWARD_ICON,
  EYE_OPEN_ICON,
  EYE_CLOSE_ICON,
  DISCLAIMER
}
